<div class="differente_hypnose">
    <div class="block_hypnose" *ngFor="let value of listImageContent">
        <img src="{{ value.image }}" alt="{{ value.name }}">
        <div class="contenu_hypnose">
            <h2>{{ value.title }}</h2>
            <p>
                {{ value.content }}
            </p>
        </div>
    </div>
</div>