<div class="container">
    <h2 class="titre_hypnose">Hypnose</h2>

    <div class="content_hypnose">
        <h3 class="titre_paragraphe">Il était une fois l’inconscient…</h3>
        <div class="premier_block_paragraphe_hypnose">
            <div class="content_block_paragraphe_hypnose">
                <p>
                    L’inconscient emmagasine les événements
                    de votre vie et les informations de
                    l’environnement qui l’entourent depuis
                    notre naissance et même avant, il nous
                    indique la voie à suivre.
                </p>
                <p>
                    C’est lui qui guide 90% de nos actes. Il trie,
                    traite et organise l’information de façon
                    très simpliste et très basique, il propose
                    des solutions qui sont tout à fait autre
                    qu’un raisonnement &lt;&lt; logique et très
                    mental &gt;&gt; pourrait amener.
                </p>
                <p>
                    En hypnothérapie nous nous adressons directement à l’inconscient en utilisant
                    des métaphore et langage imager qui même si, il ne parle pas au mental
                    atteindra l’inconscient rapidement.
                    Attendu que l’inconscient dirige en grande majorité nos actes, si nous voulons
                    acquérir de nouveau comportement, c’est donc à lui qu’il faut s’adresser, en
                    parlant son langage.
                </p>
            </div>
            <img src="../../../assets/hypnose1.jpg" alt="image arbre zen">
        </div>

        <!-- <p>
            En hypnothérapie nous nous adressons directement à l’inconscient en utilisant
            des métaphore et langage imager qui même si, il ne parle pas au mental
            atteindra l’inconscient rapidement.
        </p> -->
        <!-- <p>
            Attendu que l’inconscient dirige en grande majorité nos actes, si nous voulons
            acquérir de nouveau comportement, c’est donc à lui qu’il faut s’adresser, en
            parlant son langage.
        </p> -->

    </div>

    <!-- Ce que l'hypnose n'est pas et ce qu'elle est ! -->
    <div class="content_hypnose">
        <h3 class="titre_paragraphe">Ce que l’hypnose n’est pas et ce qu’elle est !</h3>
        <strong>
            En aucun cas l’hypnose ne peut remplacer un traitement médical, l’hypnose
            peut accompagner un traitement.
        </strong>
        <ul>
            <li>
                La tendance des médias, à privilégier le sensationnel, est à l’origine de
                nombreux malentendus et de fausses idées sur l’hypnose thérapeutique.
            </li>
            <li>
                Il s’agit d’une forme de contrôle de l’esprit : FAUX ! rien n’est plus
                éloigné de la vérité. Vous n’abandonnez pas votre volonté quand vous
                êtes sous hypnose. L’hypnothérapeute n’a pas de « pouvoir à
                proprement parler.
            </li>
            <li>
                Sous hypnose, les gens ne révèlent pas leurs secrets les plus profond, la
                responsabilité principale de votre inconscient est de vous protéger. Vous
                entendez tout ce que je dis et par la suite, vous vous rappellerez de la
                séance.
            </li>
            <li>
                Personne ne fait rien sous hypnose qui pourrait violer SES CONVICTIONS
                OU SES VALEURS OU LA MISE EN DANGER DE SA VIE.  
            </li>
        </ul>

    </div>

    <!-- Comment se déroule une séance d’hypnose… -->
    <div class="content_hypnose">
        <h3 class="titre_paragraphe">Comment se déroule une séance d’hypnose…</h3>
        <strong>On pourra résumer ces différentes phases de travail ainsi :</strong>
        <ul>
            <li>Une discussion sur <strong>vos besoins et vos objectifs.</strong> </li>
            <li>
                Un échange pour en savoir un peu plus sur ce qui est important pour
                vous dans la vie, vos croyances, vos priorités, des choses que vous
                craignez, mais aussi d’éventuels antécédents médicaux ou la prise de
                médicaments.
            </li>
            <li>
                L’explication du thérapeute sur le déroulement des séances et du travail
                qu’il va effectuer avec vous.
            </li>
            <li>
                Viendra ensuite la séance à proprement parler. Le maitre mot, 
                <strong>c’est la détente !</strong>  
                Votre praticien est un professionnel, vous devez vous rassurer.
            </li>
        </ul>
        <h3 class="titre_paragraphe">LES ÉTAPES PENDANT LA SÉANCE</h3>
        <p>
            La séance peut se découper en différentes phases de travail de la manière
            suivante :
        </p>
        <ul>
            <div class="premier_block_paragraphe_hypnose">
                <div class="content_block_paragraphe_hypnose">
                    <li>
                        Un dialogue s’installe en début de séance pour amener le réconfort, le
                        confort et la détente.
                    </li>
                    <li>
                        L’Induction - l’hypnothérapeute vous
                        emmènera ensuite dans un état de
                        relaxation physique et mentale profond au
                        travers d’exercices et de jeux d’imagination.
                        La durée de cette phase dépend du temps
                        que la personne va mettre pour se laisser
                        complètement allez à la détente.
                    </li>
                    <li>
                        Une fois profondément détendu,
                        l’hypnothérapeute peut alors commencer le
                        « travail de changement » en s’adressant
                        directement à votre inconscient. Votre
                        praticien utilisera des suggestions qu’il aura
                        préparées au préalable afin de vous aider à
                        changer vos modes de pensées pour
                        atteindre vos objectifs.
                    </li>
                    <li>
                        Une fois que le « travail de changement » est terminé,
                        l’hypnothérapeute va commencer la transition vers « l’éveil ». Vous vous
                        sentirez parfaitement détendu pour commencer à profiter des
                        changements.
                    </li>
                    <li>
                        Un temps sera consacré aux questions, à la description des sensations
                        ressenties lors de la séance, mais aussi aux prochains objectifs à
                        atteindre.
                    </li>
                </div>
                <img src="https://cdn.pixabay.com/photo/2021/02/22/05/46/soul-6038921_960_720.jpg" alt="">
            </div>

            <!-- <li>
                Une fois que le « travail de changement » est terminé,
                l’hypnothérapeute va commencer la transition vers « l’éveil ». Vous vous
                sentirez parfaitement détendu pour commencer à profiter des
                changements.
            </li>
            <li>
                Un temps sera consacré aux questions, à la description des sensations
                ressenties lors de la séance, mais aussi aux prochains objectifs à
                atteindre.
            </li> -->
        </ul>
        <p>
            L’avantage d’une thérapie avec l’hypnose, c’est qu’elle s’adapte à tout le
            monde, <strong>rien n’est imposé et chacun avance à son propre rythme.</strong>  Vous n’aurez
            aucun effort à faire sinon celui de vous détendre et de vous laisser guider.
        </p>
    </div>

    <!-- qu'est-ce que l'hypnose peut soigner ? -->
    <div class="content_hypnose">
        <h3 class="titre_paragraphe">Qu'est-ce que l'hypnose peut soigner ?</h3>
        <ul>
            <li>Gestion de la douleur</li>
            <li>Dépression, mal être</li>
            <li>Perte de confiance en soi</li>
            <li>Le stress, l’insomnie</li>
            <li>Dépendances (alcool, tabac …)</li>
            <li>Traumatismes petits ou grands</li>
            <li>Choix difficile, évolution personnelle</li>
            <li>Burnout</li>
            <li>Trouver sa place dans sa vie</li>
        </ul>
        <p>
            Et encore beaucoup d’autres choses, envoyez-moi un message privé si vous
            avez des questions j’y repondérais le plus clairement possible.
        </p>
        <p class="soulignement_paragraphe_hypnose">L’hypnothérapeute s’engage à respecter :</p>
        <ul>
            <li>Les cadres et principes généraux de la méthode.</li>
            <li>
                Les valeurs que défend l’hypnose : responsabilité, tolérance, respect de
                la liberté individuelle et de la dignité humaine, tant envers ses clients
                qu’envers ses confrères.
            </li>
            <li>
                Le secret professionnel envers ses clients
            </li>
        </ul>
    </div>
</div>
