<header>
    <nav>
        <div class="logo">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="73" height="85" viewBox="0 0 73 85">
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="36.5" cy="36.5" r="36.5" transform="translate(0 9)" fill="#d9a5a0"/>
                <g id="Composant_1_1" data-name="Composant 1 – 1" transform="translate(8)">
                    <text id="S" transform="translate(33 73)" fill="#f5f5f5" font-size="44" font-family="Dosis-Bold, Dosis" font-weight="700"><tspan x="0" y="0">S</tspan></text>
                    <text id="S-2" data-name="S" transform="translate(0 69)" fill="#f5f5f5" font-size="67" font-family="Dosis-Bold, Dosis" font-weight="700"><tspan x="0" y="0">S</tspan></text>
                </g>
            </svg> -->
            Ssyncronime
        </div>
        <div class="burger" >
            <input type="checkbox" id="check"/>
            <div class="checkbtn" for="#check" (click)="navbarBtn()">
                <i class="fas fa-bars"></i>
            </div>
        </div>
        <div class="nav-item">
            <ul id="checkList">
                <li class="items" *ngFor="let item of list_link"><a routerLink="{{ item.link }}" class="items_link">{{ item.title }}</a></li>
            </ul>
        </div>
    </nav>
</header>