<div class="container">
    <div class="cercle_container">
        <div class="content">
            <div class="picture_me">
                <img src="../../../assets/photo_me.png" alt="photo_me.png">
                <h2>Sabrina</h2>
            </div>
            <div class="description_me">
                <!-- <h3>Qui suis-je ?</h3> -->
                <p>
                    Sabrina Lefevre, praticienne en hypnose de bien-être et Coach de vie.
                    <!-- Forte de mes expériences personnelles, je suis persuadée que dans certains moments de
                    notre vie nous avons besoins d’être guidés pour faire face à des situations difficiles. 
                    Rester seul c’est prolonger son état de mal-être. -->
                </p>
                <p>
                    Ma volonté est de contribuer au bien-être et à l’épanouissement de mes patients grâce à
                    mes capacités d’empathie, de respect de l’autre dans sa différence et à travers les difficultés
                    qu’il peut rencontrer durant sa vie. 
                </p>
                <p>
                    Mon accompagnement est basé sur le renforcement de la personne par le biais de la
                    gestion des émotions. 
                    Parce que l’hypnose que je pratique est belle, douce et respectueuse, elle est à l’opposé de
                    la manipulation et des shows médiatiques et, surtout, dans bien des cas, elle peut être
                    positivement surprenante et concourir ainsi à vous assister dans la quête de votre mieux-être.
                </p>
                <p>
                    Permettez-moi de vous apprendre à utiliser vos pouvoirs hypnotiques et vos ressources
                    personnelles afin de vous aider à aller mieux, à vous libérer et de vous autoriser, tout
                    simplement, à vivre plus heureux. 
                    Nous pourrons, si vous le voulez, naviguer ensemble vers le ou les objectifs qui sont les
                    vôtres.
                    <!-- Cependant, vous resterez toujours le capitaine de votre embarcation. -->
                </p>
            </div>
        </div>
        <div class="cercle a"></div>
        <div class="cercle b"></div>
        <div class="cercle c"></div>
        <div class="cercle d"></div>
        <div class="cercle e"></div>
        <div class="cercle f"></div>
    </div>
    <div class="hypnose_explication_container">
        <div class="contenu_hypnose_explication">
            <h2 class="titre_hypnose_explication">l'Hypnose</h2>
            <p>
                L'hypnose est l'outil de changement le plus puissant actuellement connu dans le
                monde.
                Contrairement à ce que pense la majorité, l'état hypnotique n'a rien à voir avec
                le sommeil ordinaire, il s'agit plutôt d'un état de relaxation qui peut atteindre
                différents niveaux de profondeur. La personne reste toujours consciente de ce
                qu'il se passe, même au cours de l’hypnose la plus profonde et elle se souvient de
                tout après la séance.
                C’est un état modifié de conscience, une modification de la perception, un moyen
                d’accéder à l’inconscient afin de se libérer d’impasses intérieures, relationnelles ou
                situationnelles.
                L’hypnose permet de résoudre des conflits intérieurs, de faire de meilleurs choix
                pour vous, de transformer des émotions et sensations désagréables, un mal être en
                bien être. Cela permet de changer de regard sur soi et le monde, de modifier sa
                façon d’être, ses habitudes nocives et sa façon de se comporter.
                Les suggestions et métaphores employées dans l’hypnose permettent ce
                changement. Elles sont fournies en collaboration avec le patient.
            </p>
            <div class="therapie_hypnose">
                <p>
                    La thérapie par hypnose agit à deux niveaux :
                </p>
                <ul>
                    <li>
                        Pendant la séance :  sur l’état physique et émotionnel par la
                        compréhension des causes et des mécanismes du problème. Le ressenti
                        inscrit dans le corps peut être modifié et transformé en faisant appel
                        aux ressources profondes de la personne. Un bien-être intérieur
                        s’installe grâce aux changements amorcés et à la reprogrammation des
                        anciens schémas de pensées négatives.
                    </li>
                    <li>
                        Après la séance : le bienfait de la séance peut être renforcé par des
                        exercices de changement d’habitudes comportementales dans le
                        « système » relationnel qui le caractérise.
                    </li>
                </ul>
            </div>
            <p>
                Les séances sont espacées de 2 à 3 semaines pour laisser le temps à l'inconscient
                d'intégrer le changement.
            </p>
        </div>
    </div>
    <app-differente-hypnose></app-differente-hypnose>
</div>