<div class="container">
    <div class="logo">
        Ssyncronime
    </div>
    <div class="info">
        <div class="nom_info">
            <i class="fas fa-user"></i>
            <p>Sabrina</p>
        </div>
        <div class="email_info">
            <i class="fas fa-at"></i>
            <a href="mailto:Ssyncronime@hotmail.com">Ssyncronime@hotmail.com</a>
        </div>
        <div class="tel_info">
            <i class="fas fa-phone-alt"></i>
            <p>+32 0472/05.72.74</p>
        </div>
        <div class="ville_info">
            <i class="fas fa-map-marker-alt"></i>
            <p>Nassogne - Marche en Famenne - Rochefort - St Hubert</p>
        </div>
    </div>
</div>