<div class="msg_attente">
    <p>Le formulaire de prise de rendez vous est en travaux </p>
    <p>Vous pouvez prendre rendez vous par :</p>
    <div class="tel">
        <i class="fas fa-phone-alt"></i>
        <p> +32 0472/05.72.74</p>
    </div>
    <div class="email">
        <i class="fas fa-at"></i>
        <p> Ssyncronime@hotmail.com</p>
    </div>
</div>
<div class="container">
    <h2 class="titre_contact">Contact</h2>
    
    <form #f ="ngForm" (ngSubmit)="contact(f)" novalidate>
                    <input 
                        type="text" 
                        name="nom"
                        placeholder="Votre Nom"
                        ngModel 
                        required
                        disabled
                    />
                    <input 
                        type="email" 
                        name="email" 
                        placeholder="Votre Email"
                        ngModel 
                        required
                        disabled
                    />
                    
                <!-- <span>
                    <i class="fas fa-ticket-alt"></i>
                </span> -->
                    <input 
                        type="text"  
                        name="objetMessage"
                        placeholder="Objet Du Message"  
                        ngModel
                        required
                        disabled
                    />
                    
                <!-- <span>
                    <i class="fas fa-envelope"></i>
                </span> -->
                <textarea 
                    name="message" 
                    placeholder="Message"
                    ngModel
                    cols="30" 
                    rows="10"
                    disabled
                >
                </textarea>
                
                <button 
                    class="btn-submit" 
                    type="submit"
                    disabled
                >
                    Contact me
                </button>
    </form>
</div>
